import React, {useState} from 'react';
import {cloneDeep} from "lodash";
import {
  Checkbox
} from "@material-ui/core";
import DataTable from 'react-data-table-component';
import {useMutation, useQuery} from "@apollo/client";
import {
  CLOSE_MERGE_REQUEST,
  CREATE_MERGE_REQUEST,
  GET_CONFIG_DATA,
  GET_CONFIG_HISTORY,
  UPDATE_CONFIG,
} from "../../graphql/queries/config";
import {JsonEditor as Editor} from "jsoneditor-react";
import Button from "@material-ui/core/Button";
import PaperModal from "../common/PaperModal";
import ConfigReview from "./ConfigReview";
import ConfirmationModal from "../common/warning-modal";
import 'react-diff-view/style/index.css';
import DiffChecker from "../DiffChecker";
import ConflictResolver from "./ConflictResolver";

function ConfigHistory({config, openEditingForm, onClose}) {
  const { loading, error, data, refetch } = useQuery(GET_CONFIG_HISTORY, {variables: { configId: config.id }});
  const newData = cloneDeep(data);
  const [showReview, setShowReview] = useState(false);
  const [showDiffCheck, setShowDiffCheck] = useState(false)
  const [oldConfig, setOldConfig] = useState({})
  const [newConfig, setNewConfig] = useState({})
  const [showDiffResolver, setShowDiffResolver] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [showMergeConfirmationModal, setShowMergeConfirmationModal] = useState(false);
  const [updateConfig, updateConfigResponse] = useMutation(
    UPDATE_CONFIG,
    {
      refetchQueries: [
        {query: GET_CONFIG_DATA, variables: {configType: config.configType}},
        {query: GET_CONFIG_HISTORY, variables: {configId: config.id}},
      ]
    }
  );
  const [closeMergeRequest, closeMergeRequestResponse] = useMutation(CLOSE_MERGE_REQUEST)
  const [createMergeRequest, mergeRequestResponse] = useMutation(CREATE_MERGE_REQUEST);

  if (loading) {
    return <>Loading</>
  }

  if (error) {
    return <>Error</>
  }

  if (newData.configHistory.edges.length === 0 && newData.mergeRequests.edges.length === 0)
    return <>No entries</>

  return (
    <>
      <ConfirmationModal
        headerText="Are you sure you want to merge this config change?"
        contentText="This will affect production immediately if config is activated"
        showModal={showMergeConfirmationModal}
        hideModalHandler={() => setShowMergeConfirmationModal(false)}
        cancelHandler={() => setShowMergeConfirmationModal(false)}
        submitHandler={() => {
          setShowMergeConfirmationModal(false);
          updateConfig({variables:{id: config.id, mergeRequestId: currentReview.id}}).then(
            r => {
              if (r.data && r.data.updateConfig && r.data.updateConfig.ok) {
                onClose();
              }
            }
          )
        }}
      />

      <PaperModal style={{position:"relative"}} open={showDiffResolver} onClose={() => {setShowDiffResolver(false)}}>

      <ConflictResolver
        className="custom"
        activeConfig={config}
        selectedConfig={currentReview}
        showResolver={setShowDiffResolver}
        updateConfigReview={setCurrentReview}
      />

      </PaperModal>

      <PaperModal open={showReview} onClose={() => {setShowReview(false)}}>
        {
          currentReview && <ConfigReview
            configType={currentReview.configType}
            baseConfig={JSON.parse(currentReview.baseConfigHistoryObj.config)}
            newConfig={JSON.parse(currentReview.config)}
            comments={JSON.parse(currentReview.comments)}
            reviews={JSON.parse(currentReview.reviews)}
            reviewId={currentReview.id}
            configId={config.id}
            onClose={() => {setShowReview(false)}}
          />
        }
      </PaperModal>

      <PaperModal open={showDiffCheck} onClose={() => {setShowDiffCheck(false)}}>
        <DiffChecker
          oldValue={oldConfig}
          newValue={newConfig}
        />
      </PaperModal>

      <DataTable
        title={"Pending reviews for " + config.name}
        columns={
          [
            {
              name: 'ID',
              selector: 'node.id',
              sortable: true,
            },
            {
              name: 'JSON',
              selector: 'node.config',
              sortable: false,
              cell: (row, index, column, id) => (
                <div className="full-width">
                  <Editor
                    value={JSON.parse(row.node.config)}
                    mode={'view'}
                  />
                  {
                    row.node.baseConfigHistoryId != config.configHistoryId && "This configuration is based on the old version that is no longer in production."
                  }
                </div>
              ),
              grow: 4
            },
            {
              name: 'Description',
              selector: 'node.description',
              sortable: true
            },
            {
              name: 'Added at',
              selector: 'node.createdAt',
              sortable: true,
              format: row => row.node.createdAt && new Date(Date.parse(row.node.createdAt)).toLocaleString(),
            },
            {
              name: 'Added by',
              selector: 'node.createdBy',
              sortable: true,
            },
            {
              name: 'Actions',
              cell: (row, index, column, id) => {
              if(row.node.baseConfigHistoryId != config.configHistoryId){
                row.node.reviews = JSON.parse(row.node.reviews);
                row.node.reviews.push({"email": "dev_docker@creditninja.com", "status": "fix-conflict"})
                row.node.reviews = JSON.stringify(row.node.reviews)
              }
              let statuses = JSON.parse(row.node.reviews).map(item => item.status);
                return <div className="full-width">
                  {
                    (statuses.includes("fix-conflict")) && <Button
                      className="large-button"
                      variant="contained"
                      color="primary"
                      onClick={
                        () => {
                          setCurrentReview(row.node);
                          setShowDiffResolver(true);
                        }
                      }
                    >
                      Resolve Conflicts
                    </Button>
                  }

                  {
                  (!statuses.includes("fix-conflict"))  && <Button
                      className="large-button"
                      variant="contained"
                      color="primary"
                      onClick={
                        () => {
                          openEditingForm(
                            {
                              ...row.node.baseConfigHistoryObj,
                              config: JSON.parse(row.node.baseConfigHistoryObj.config)
                            },
                            JSON.parse(row.node.config),
                            "update",
                            row.node
                          )
                        }
                      }
                    >
                      Edit
                    </Button>
                  }

                  {
                  (!statuses.includes("fix-conflict")) && <Button
                      className="large-button"
                      variant="contained"
                      color="primary"
                      onClick={
                        () => {
                          setCurrentReview(row.node);
                          setShowReview(true);
                        }
                      }
                    >
                      Review
                    </Button>
                  }

                  {
                  (!statuses.includes("fix-conflict") && statuses.includes("approve") && !statuses.includes("request changes")) && <Button
                      className="large-button"
                      variant="contained"
                      color="secondary"
                      disabled={!!updateConfigResponse.loading}
                      onClick={
                        () => {
                          setCurrentReview(row.node);
                          setShowMergeConfirmationModal(true);
                        }
                      }
                    >
                      Merge Config
                    </Button>
                  }

                  <Button
                    className="large-button"
                    variant="contained"
                    color="secondary"
                    disabled={!!closeMergeRequestResponse.loading}
                    onClick={
                      () => {
                        closeMergeRequest({variables:{id: row.node.id}}).then(
                          r => {
                            if (r.data && r.data.closeMergeRequest && r.data.closeMergeRequest.ok) {
                              refetch();
                            }
                          }
                        )
                      }
                    }
                  >
                    Close Merge Request
                  </Button>

                </div>
              }
            }
          ]
        }
        data={newData.mergeRequests.edges}
      />
      <DataTable
        title={"History of changes for config " + config.name}
        columns={
          [
            {
              name: 'ID',
              selector: 'node.id',
              sortable: true,
            },
            {
              name: 'JSON',
              selector: 'node.config',
              sortable: false,
              cell: (row, index, column, id) => (
                <div className="full-width">
                  <Editor
                    value={JSON.parse(row.node.config)}
                    mode={'view'}
                  />
                </div>
              ),
              grow: 4
            },
            {
              name: 'Description',
              selector: 'node.mergeRequestObj.description',
              sortable: true,
              cell: (row, index, column, id) => row.node.mergeRequestObj?.description,
            },
            {
              name: 'Added at',
              selector: 'node.createdAt',
              sortable: true,
              format: row => row.node.createdAt && new Date(Date.parse(row.node.createdAt)).toLocaleString(),
            },
            {
              name: 'Added by',
              selector: 'node.createdBy',
              sortable: true,
            },
            {
              name: 'Actions',
              cell: (row, index, column, id) => (
                <div className="full-width">
                  {
                    row.node.id !== config.configHistoryId ? <Button
                      className="large-button"
                      variant="contained"
                      color="primary"
                      disabled={!!mergeRequestResponse.loading}
                      onClick={
                        () => {
                          createMergeRequest({
                            variables: {
                              configId: config.id,
                              config: row.node.config,
                              baseConfigId: config.configHistoryId,
                              description: "This is a revert to #" + row.node.id + " (" + row.node.mergeRequestObj?.description + ")"
                            },
                            refetchQueries: [
                              {query: GET_CONFIG_HISTORY, variables: {configId: config.id}},
                            ]
                          }).then(r => {

                          });
                        }
                      }
                    >
                      Revert to this version
                    </Button> : <>This is the current version of selected config</>
                  }

                  {
                    index !== newData.configHistory.edges.length - 1 && <Button
                      className="large-button"
                      variant="contained"
                      color="primary"
                      onClick={
                        () => {
                          setOldConfig(JSON.parse(newData.configHistory.edges[index + 1].node.config))
                          setNewConfig(JSON.parse(newData.configHistory.edges[index].node.config))
                          setShowDiffCheck(true)
                        }
                      }
                    >
                      Diff check with prev version
                    </Button>
                  }

                </div>
              ),
            },
          ]
        }
        data={newData.configHistory.edges}
        selectableRows
        onSelectedRowsChange={(state) => {}}
        selectableRowsComponent={Checkbox}
        // clearSelectedRows={toggledClearRows}
        // contextActions={
        // }
      />
    </>
  );
};

export default ConfigHistory;
