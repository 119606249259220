import React, { useState } from "react";
import { Paper } from "@material-ui/core";

import { CampaignFormErrorText } from "../common/form_styles";
import { useMutation } from "@apollo/client";
import {UPDATE_RULE_TO_PROMO_OFFER} from "../../graphql/queries/promotionalOffers";
import RulesToPromoOffersForm from "./RulesToPromoOffersForm";

function UpdateRulesToPromoOffersSection({ruleToPromoOfferData, refetch, closeUpdateSection}) {
  const [error, setError] = useState(false)
  const [updateRuleToPromotionalOffer] = useMutation(UPDATE_RULE_TO_PROMO_OFFER);

  const submitHandler = (data) => {
    updateRuleToPromotionalOffer({
      variables: {
        ruleToPromoOfferId: parseInt(ruleToPromoOfferData.id),
        payload: JSON.stringify(data)
      },
    }).then(r => {
      if (r.data.updateRuleToPromotionalOffer.ok === false)
        setError(r.data.updateRuleToPromotionalOffer.info)
      else {
        refetch()
        setError(false)
        closeUpdateSection()
      }
    });
  };

  return (
    <>
      <Paper>
        <RulesToPromoOffersForm
          onSubmit={submitHandler}
          createMode={false}
          ruleToPromoOfferData={ruleToPromoOfferData}
          refetch={refetch}
        />
        {
          error &&
          <CampaignFormErrorText>
            {error}
          </CampaignFormErrorText>
        }
      </Paper>
    </>
  );
}

export default UpdateRulesToPromoOffersSection;
