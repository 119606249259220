import gql from "graphql-tag";

const GET_PROMOTIONAL_OFFER_DEFINITIONS = gql`
query getPromotionalOfferDefinitions {
  promotionalOfferDefinitions {
    edges {
      node {
        id,
        loanProgramName,
        interestRate,
        term,
        termConstant,
        apr,
        annualFee,
        originationFee,
        offerDescription,
        customizedMessage,
        cannedText
      }
    }
  }
}
`;

const GET_RULES_TO_PROMOTIONAL_OFFERS = gql`
query getRulesToPromotionalOffers {
  rulesToPromotionalOffers {
    edges {
      node {
        id,
        campaignId,
        state,
        promotionalOfferId
      }
    }
  }
}
`;

const CREATE_PROMOTIONAL_OFFER_DEFINITION = gql`
mutation createPromotionalOfferDefinition ($payload: JSONString) {
  createPromotionalOfferDefinition (payload: $payload) {
    ok
    info
    errors
    promoOfferDef {
      id,
      loanProgramName,
      interestRate,
      term,
      termConstant,
      apr,
      annualFee,
      originationFee,
      offerDescription,
      customizedMessage,
      cannedText
    }
  }
}
`;

const UPDATE_PROMOTIONAL_OFFER_DEFINITION = gql`
mutation ($payload: JSONString, $promoOfferDefId: Int) {
  updatePromotionalOfferDefinition(payload: $payload, promoOfferDefId: $promoOfferDefId) {
    ok
    info
    errors
    promoOfferDef
  }
}
`;

const CREATE_RULES_TO_PROMO_OFFERS = gql`
mutation createRulesToPromotionalOffers ($payload: JSONString) {
  createRulesToPromotionalOffers (payload: $payload) {
    ok
    info
    errors
    rulesToPromoOffers
  }
}
`;

const UPDATE_RULE_TO_PROMO_OFFER = gql`
mutation ($payload: JSONString, $ruleToPromoOfferId: Int) {
  updateRuleToPromotionalOffer(payload: $payload, ruleToPromoOfferId: $ruleToPromoOfferId) {
    ok
    info
    errors
    ruleToPromoOffer
  }
}
`;

const REMOVE_RULE_TO_PROMO_OFFER = gql`
mutation ($ruleToPromoOfferId: Int) {
  removeRuleToPromotionalOffer(ruleToPromoOfferId: $ruleToPromoOfferId) {
    ok
    info
    errors
    ruleToPromoOffer
  }
}
`;


export {
  GET_PROMOTIONAL_OFFER_DEFINITIONS,
  GET_RULES_TO_PROMOTIONAL_OFFERS,
  UPDATE_PROMOTIONAL_OFFER_DEFINITION,
  UPDATE_RULE_TO_PROMO_OFFER,
  REMOVE_RULE_TO_PROMO_OFFER,
  CREATE_PROMOTIONAL_OFFER_DEFINITION,
  CREATE_RULES_TO_PROMO_OFFERS
}